import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUser {
  id: string;
  leader_id: string;
  unti_id: string;
  isLoggedIn: boolean;
  token: string | null;
}

const initialState: IUser = {
  token: localStorage.getItem("gametest-token"),
  isLoggedIn: false,
  id: "",
  leader_id: "",
  unti_id: "",
};

// TODO: в loading опционально добавить label, убирать если loading = false;
const authSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    login(state, action: PayloadAction<IUser>) {
      return { ...action.payload, isLoggedIn: true };
    },
    logout(state) {
      return { ...state, isLoggedIn: false };
    },
  },
});

const { actions, reducer } = authSlice;

export const { login } = actions;

export default reducer;
