import { useEffect, useState } from "react";
import { Layer, Stage, Image } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useImage from "use-image";
import styled from "styled-components";

import { selectActivities, selectActivityById } from "../store/activities/activities.selector";
import { fetchActivities } from "../store/activities/activities.reducer";
import Polygon from "../components/Polygon";
import PolygonCircle from "../components/PolygonCircle";
import ActivityFrame from "../components/ActivityFrame";
import BaseInfo from "../components/BaseInfo";

import baseImage from "../assets/base.jpg";
import titleImage from "../assets/base-title.png";
import { getStatus, IActivity, StatusType } from "../utils/activities";

const BaseWrapper = styled.div`
  position: relative;
`;

const houses = [
  {
    points: [
      180, 505, 180, 670, 138, 670, 138, 650, 116, 650, 116, 670, 75, 670, 75, 503, 180, 505,
    ],
    circle: <PolygonCircle x={130} y={500} num="1" />,
  },
  {
    points: [
      318, 505, 318, 670, 282, 670, 282, 650, 258, 650, 258, 670, 220, 670, 220, 505, 318, 505,
    ],
    circle: <PolygonCircle x={270} y={500} num="2" />,
  },
  {
    points: [455, 505, 455, 670, 356, 670, 356, 505, 455, 505],
    circle: <PolygonCircle x={405} y={500} num="3" />,
  },
  {
    points: [594, 505, 594, 668, 490, 668, 490, 505, 594, 505],
    circle: <PolygonCircle x={542} y={500} num="4" />,
  },
  {
    points: [852, 460, 852, 554, 630, 554, 630, 460, 852, 460],
    circle: <PolygonCircle x={740} y={462} num="5" />,
  },
  {
    points: [871, 9, 880, 100, 695, 120, 692, 82, 674, 84, 668, 28, 871, 9],
    circle: <PolygonCircle x={790} y={112} num="6" />,
  },
  {
    points: [143, 210, 143, 331, 75, 331, 75, 210, 143, 210],
    circle: <PolygonCircle x={145} y={270} num="7" />,
  },
];

const BackGround = () => {
  const [image] = useImage(baseImage);
  return <Image image={image} />;
};

const BaseTitle = () => {
  const [image] = useImage(titleImage);
  return <Image image={image} />;
};

function Base() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activities = useSelector(selectActivities);
  const [uuid, setUuid] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const activity = useSelector(selectActivityById(uuid));

  // Обновляем список активностей
  useEffect(() => {
    dispatch(fetchActivities());
  }, [dispatch]);

  useEffect(() => {
    const isFinished = activities.every((activity) => activity.status);
    if (activities.length > 0 && isFinished) {
      navigate("/results");
    }
  }, [activities, navigate]);

  // Закрываем информацию об активности
  const handleClose = () => {
    setUuid("");
    setIsVisible(false);
  };

  // Заканчиваем с активностью и получаем результаты
  const handleFinish = (result: any) => {
    let status: StatusType | undefined;
    if (activity) {
      // Сравниваем результаты с порогом прохождения и возвращаем статус
      status = getStatus(activity, result.results);

      if (status === "passed") {
        navigate(`/mission/success/${activity.uuid}`);
        // navigate(`/success/${activity.uuid}`);
      } else {
        navigate(`/mission/fail/${uuid}`);
        // navigate(`/fail/${uuid}`);
      }
    }
  };

  // Показываем описание активности
  const handleShowInfo = (activity: IActivity) => {
    if (activity) {
      if (!activity.status) {
        setUuid(activity.uuid);
      }
    }
  };

  // Отображаем ифрейм
  const handleShowIframe = () => setIsVisible(true);

  return (
    <BaseWrapper>
      {activity && (
        <BaseInfo
          description={activity.description}
          onCancel={handleClose}
          onOk={handleShowIframe}
        />
      )}
      {activity && isVisible && (
        <ActivityFrame uuid={activity.uuid} url={activity.url} onFinish={handleFinish} />
      )}
      <Stage width={1024} height={680}>
        <Layer>
          <BackGround />
          <BaseTitle />
        </Layer>
        <Layer>
          {activities.map(
            (activity, index) =>
              index <= houses.length && (
                <Polygon
                  key={activity.uuid}
                  points={houses[index].points}
                  circle={houses[index].circle}
                  activity={activity}
                  onClick={handleShowInfo}
                />
              )
          )}
        </Layer>
      </Stage>
    </BaseWrapper>
  );
}

export default Base;
