import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectAuth } from '../store/auth/auth.selector';

const Iframe = styled.iframe<{ isVisible: boolean }>`
  position: absolute;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: 100%;
  z-index: 3;
  border: none;
  background-color: #202124;
`;

type ActivityFrameProps = {
  url: string;
  uuid: string;
  onFinish: (result?: any) => void;
};

function ActivityFrame({ url, uuid, onFinish }: ActivityFrameProps) {
  const { token, unti_id } = useSelector(selectAuth);
  const [height, setHeight] = useState(680);
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const requestRef = useRef(0);

  const mountNode = contentRef?.contentWindow?.document;

  // Ресайзинг ифрейма
  const resize = useCallback(() => {
    if (mountNode?.body) {
      const scrollHeight = mountNode?.body.scrollHeight;
      if (height !== scrollHeight && scrollHeight > 680) {
        setHeight(scrollHeight);
      }
    }
    requestRef.current = requestAnimationFrame(resize);
  }, [height, mountNode?.body]);

  useLayoutEffect(() => {
    requestRef.current = requestAnimationFrame(resize);
    return () => cancelAnimationFrame(requestRef.current);
  }, [resize]);

  // Подключаем скрипт и монтируем его в iframe
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    mountNode?.body.appendChild(script);

    return () => {
      if (mountNode) {
        mountNode.body.removeChild(script);
      }
    };
  }, [mountNode, url]);

  // Перехватываем сообщение FINISH_ACTIVITY от iframe
  const handleFinish = useCallback(
    (e: MessageEvent) => {
      const result = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
      //console.log("result :>> ", e.data);
      if (typeof e.data !== 'object') {
        console.log('result :>> ', e.data);
      }

      result.finish && onFinish(result);
    },
    [onFinish]
  );

  useLayoutEffect(() => {
    window.addEventListener('message', handleFinish);
    return () => window.removeEventListener('message', handleFinish);
  }, [handleFinish]);

  return (
    <Iframe key={uuid} ref={setContentRef} isVisible={!!url} title={uuid} height={height}>
      {mountNode?.body &&
        createPortal(
          <div
            id="webbot"
            className="activity-webbot"
            data-unti-id={unti_id}
            data-activity-uuid={uuid}
            data-user-token={token}
            data-dark-mode="true"
            data-game-test="true"
          />,
          mountNode?.body
        )}
    </Iframe>
  );
}

export default ActivityFrame;
