import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionAPI } from "../../services/questions.services";

export interface IQuestion {
  id: string;
  text: string;
  answer?: string;
  duration?: number;
}

const initialState: IQuestion[] = [];

export const fetchQuestions = createAsyncThunk("questions/fetch", async () => {
  const questions = await QuestionAPI.fetch();
  return questions;
});

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestions(_, action: PayloadAction<IQuestion[]>) {
      return action.payload;
    },
    setAnswer(
      state,
      { payload }: PayloadAction<{ id: string; answer?: string; duration?: number }>
    ) {
      return state.map((q) =>
        q.id === payload.id ? { ...q, answer: payload.answer, duration: payload.duration } : q
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestions.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = questionsSlice;

export const { setQuestions, setAnswer } = actions;

export default reducer;
