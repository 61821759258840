import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { QuestionAPI } from "../services/questions.services";
import { credits } from "../utils/credits";

import NewsBlock from "../components/NewsBlock";
import Leading from "../components/Leading";
import { Button, Credit, IntroduceWrapper, Title, UrgentNews, Video } from "../common/News";

import introVideo from "../assets/intro.mp4";
import urgentNewsImage from "../assets/urgent-news.png";

function Introduce() {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState<boolean | undefined>();
  const [creditIndex, setCreditIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    (async () => {
      const answers = await QuestionAPI.fetchAnswers();
      if (answers.length > 0) {
        navigate("/base");
      }
    })();
  }, [navigate]);

  const handleStop = () => {
    setIsPlaying(false);
    setCreditIndex(4);
  };

  useEffect(() => {
    const video = videoRef.current;
    video?.addEventListener("pause", handleStop);
    return () => {
      video?.removeEventListener("pause", handleStop);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current?.play();
    }
  }, [isPlaying]);

  const credit = credits[creditIndex];

  const handleClick = useCallback(() => {
    if (creditIndex >= 0 && creditIndex < 3) {
      setCreditIndex((i) => i + 1);
    } else if (creditIndex === 3) {
      setIsPlaying(true);
    } else if (creditIndex > 3 && creditIndex <= 5) {
      setCreditIndex((i) => i + 1);
    } else if (creditIndex === 6) {
      navigate("/dialogue");
    }
  }, [navigate, creditIndex]);

  return (
    <IntroduceWrapper>
      <Video ref={videoRef} src={introVideo} isVisible={isPlaying} />
      <Credit>
        <p dangerouslySetInnerHTML={{ __html: credit.text }} />
      </Credit>
      <Leading emotion={credit.emotions} />
      <Title>{credit.title}</Title>
      <NewsBlock bg={credit.img} text={credit.subject} />
      <Button onClick={handleClick}>{credit.button}</Button>
      <UrgentNews src={urgentNewsImage} alt="Срочные новости" />
    </IntroduceWrapper>
  );
}

export default React.memo(Introduce);
