import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActivityAPI } from "../../services/activities.services";
import { RootState } from "..";

import { activities, getStatus, IActivity } from "../../utils/activities";

const initialState: IActivity[] = activities;

export const fetchActivities = createAsyncThunk("activities/fetch", async (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const activities = await ActivityAPI.fetch(state.auth.unti_id);
  return activities;
});

const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    setActivities(_, action: PayloadAction<IActivity[]>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivities.fulfilled, (state, action) => {
      return state.map((item) => {
        const result = action.payload[item.uuid]?.extensions[item.extension];
        //console.log(item.uuid, action.payload[item.uuid]);
        return { ...item, status: getStatus(item, result) };
      });
    });
  },
});

const { actions, reducer } = activitiesSlice;

export const { setActivities } = actions;

export default reducer;
