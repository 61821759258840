import hackersImage from '../assets/news/hackers.jpg';
import brainstormImage from '../assets/news/brainstorm.jpg';
import terrorist from '../assets/news/terrorist.jpg';
import bigCrackImage from '../assets/news/bigcrack.jpg';
import confrontationImage from '../assets/news/confrontation.jpg';
import olimpImage from '../assets/news/olimp.jpg';
import amazonImage from '../assets/news/amazon.jpg';

type ThresholdType = { title: string; value: number; less?: boolean }[];

export type StatusType = 'passed' | 'failed';

export interface IActivity {
  uuid: string;
  extension: string;
  name: string;
  url: string;
  description: string;
  success: {
    title: string;
    text: string[];
    image: string;
    subject: string;
  };
  failed_text: string[];
  threshold: ThresholdType;
  thresholdOnlyOne?: boolean;
  status?: StatusType;
}

export const activities: IActivity[] = [
  {
    url: 'https://kios.diag.2035.university/index.js',
    uuid: 'b9bd3d49-3460-4321-b81d-c31535111ac1',
    extension: 'https://my.2035.university/xapi/v1/results/kios-ict-questionnaire',
    name: 'International Computer and Information Literacy Study: анкета по использованию ИКТ',
    threshold: [{ title: 'Навыки ИКТ', value: 3.3 }],
    description:
      'Твоей первой миссией будет участие в международной IT олимпиаде. Защити честь Народного сопротивления, покажи, на что мы способны! Или тебе слабо? ',
    success: {
      title: 'Народное сопротивление побеждает в IT',
      subject: 'IT олимпиада',
      text: [
        'В эфире новости. Сенсацией этой недели стала победа представителей “Народного сопротивления” в международной IT олимпиаде. Представители этой организации показали лучший результат.',
        'Возможно у сопротивления действительно есть шанс в противостоянии с “Кругом Вознесенных”. Время покажет, а пока, новости спорта...',
      ],
      image: olimpImage,
    },
    failed_text: [
      'Видимо я переоценил твои возможности. Все эти цифровые штучки явно не твой конек. Придется еще учится и учиться как завещал великий… как его там. Сталин! Шагом марш на занятия! ',
    ],
  },
  {
    url: 'https://kios.diag.2035.university/index.js',
    uuid: '672d2184-ae1b-4a0a-8336-104a086b851a',
    name: 'Толерантность к неопределенности',
    extension: 'https://my.2035.university/xapi/v1/results/kios-tolerance-to-uncertainty',
    thresholdOnlyOne: true,
    threshold: [
      { title: 'Ригидность', value: 50, less: true },
      { title: 'Толерантность к неопределенности', value: 20 },
    ],
    description:
      'Поступила информация о группе хакеров, запустивших вирус в полицейские базы данных. Этот вирус поражает программное обеспечение  и дестабилизирует работу системы. Ваша задача обнаружить и обезвредить хакеров, но информации о них крайне мало, поэтому справится только тот, кто умеет работать в ситуации непределенности. Проверим?',
    success: {
      title: 'Силы народного сопротивления в деле',
      subject: 'Арест Хакеров',
      text: [
        'В эфире новости. По сообщению полиции в городе была обезврежена группа хакеров, получившая название &laquo;Злые клоуны&raquo; за внешний вид масок, в которых они появлялись в сети.',
        'Их поимке полиция обязана координированным усилям бригад &laquo;Народного сопротивления&raquo;. В условиях полной неопределенности именно они смогли справиться со столь сложным заданием.',
      ],
      image: hackersImage,
    },
    failed_text: [
      'Ну…И как это понимать? Этих хакеров было всего трое. Тро-е! А вас сколько? и вы не смогли их поймать? Да похоже в это сопротивление надо было не зеленую молодежь набирать, а пенсионеров вроде меня. Мы и то с неопределенностью лучше справляемся. А ну, марш - тренироваться!',
    ],
  },

  {
    url: 'https://kios.diag.2035.university/index.js',
    uuid: 'e9733ea5-32e1-4337-85a0-d63d81498917',
    extension: 'https://my.2035.university/xapi/v1/results/kios-grit',
    name: 'GRIT',
    threshold: [{ title: 'Достижение долгосрочных целей', value: 15 }],
    description:
      'Наши коллеги пытаются внедриться в центр загрузки сознания. Но при этом они не могут взаимодействовать с основной базой. Необходимо проработать план снабжения групп действующих под прикрытием. Работать им предстоит долго, и план должен быть продуман хорошо. Проверим, сможешь ли справиться с таким заданием? ',
    success: {
      title: 'Перегруз серверов в центре загрузки сознания',
      subject: 'Случайность или теракт?',
      text: [
        'В эфире новости. Один из крупнейших центров загрузки сознания временно прекратил работу в связи со “сбоем серверов”. Корпорация “Круг вознесенных” подала официальную жалобу городским властям.',
        'По мнению оператора центра загрузки сознания, череда сбоев вызвана спланированным террористическим актом. “Явно дело рук этих надоедливых “Плотских” заявил исполнительный директор центра Янош 42-Нато.',
        'Напомним, что жаргонным термином “плотские” сторонники киборгизации называют тех, кто отказывается вживлять в тело какие-либо импланты или другие техногенные улучшения.',
      ],
      image: terrorist,
    },
    failed_text: [
      'Ну у меня просто нет слов… казалось бы, что может быть проще - план разработать. И вот надо же… Небось кто-то под руку говорил, или черная кошка дорогу перебежала? Или еще что-то помешало… танцору. Посмотрим что будет со следующим заданием.',
    ],
  },

  {
    url: 'https://taptap.diag.2035.university/index.js',
    uuid: 'dd6fff7d-0ca6-40d2-b8ea-d3126205dbbd',
    extension: 'https://my.2035.university/xapi/v1/results/tap-tap-game',
    name: 'Тап-тап',
    threshold: [{ title: 'Таптапность', value: 70 }],
    description:
      'Итак, размещение наших агентов в центре загрузки сознания наконец то дают плоды. Начинаем операцию “Вирус”: проникнем в хранилище кибер-матриц и встроим в них контролирующую программу. Так мы сможем отключать киборгов, совершающих преступления. Дело за малым - взломать электронный замок. Это я поручаю тебе. Справишься?',
    success: {
      title: 'Проникновение в хранилище данных',
      subject: 'Взлом века?',
      text: [
        'Срочные новости! Сегодня в 6 часов утра неизвестные проникли в хранилище кибер матриц крупнейшего центра загрузки сознания. Служба безопасности “Круга вознесенных” смогла засечь нарушителей только на выходе из помещения.',
        'Как сообщают пострадавшие, ни одна из кибернетических матриц не была похищена. Но, к сожалению, захватить преступников не удалось, и их истинные цели остаются неизвестными.',
      ],
      image: bigCrackImage,
    },
    failed_text: [
      'Не иначе ты из деревни Толстопальцево! По кнопкам не попал что ли? Вон пацаны школьники и то быстрее кликают. Весь план коту под хвост… Отправляйся - тренироваться.',
    ],
  },
  {
    url: 'https://kios.diag.2035.university/index.js',
    uuid: '27befc35-cac8-42b1-b0ed-096803ee3758',
    extension: 'https://my.2035.university/xapi/v1/results/kios-bruner',
    name: 'Тест Брунера',
    thresholdOnlyOne: true,
    threshold: [
      { title: 'Образное мышление', value: 6 },
      { title: 'Креативность', value: 6 },
      { title: 'Знаковое мышление', value: 6 },
      { title: 'Символическое мышление', value: 6 },
      { title: 'Предметное мышление', value: 6 },
    ],
    description:
      'Ну что же, пора взяться за дело всерьез, Собираем самую мозговитую команду за всю историю сопротивления. На кону ни много ни мало разработка стратегии полной победы над киборгами. Специальное тестирование покажет  Поглядим?',
    success: {
      title: 'Сопротивление представило команду стратегов',
      subject: 'Мозговой штурм',
      text: [
        'В Эфире новости. Наши источники в “Народном сопротивлении” сообщают, что в результате тщательного отбора и тестирования была собрана команда “Стратегов” - людей с наиболее развитым мышлением.',
        'Именно эта команда будет разрабатывать план противодействия преступлениям “Вознесенных”. Представитель “Круга вознесенных” отказался комментировать новость, ссылаясь на ее “незначительность”. Конфликт двух организаций продолжается. ',
      ],
      image: brainstormImage,
    },
    failed_text: [
      'То есть именно это твоя самая сильная сторона? Вот конкретно этот выбранный тобой показатель?  Да он даже у моего кота выше! Ну теперь понятно кого в разработку стратегии точно брать не стоит. Будем надеяться, что ты сможешь проявить себя в чем-то другом...',
    ],
  },
  {
    url: 'https://kios.diag.2035.university/index.js',
    uuid: '6a120dc0-b34c-4cfe-b500-8e94e02cc600',
    extension: 'https://my.2035.university/xapi/v1/results/kios-adaptability-and-teamwork',
    name: 'Лидерство (шкала взаимодействие с командой)',
    threshold: [{ title: 'Взаимодействие с командой', value: 3 }],
    description:
      'В этот раз миссия не совсем обычная. Назовем ее “Посвящение”. Мы собрали небольшой отряд новобранцев. Тебе предстоит организовать их и, совместно проникнув в новый центр загрузки сознания, выяснить основные параметры и уязвимости последней модели киборгов.',
    success: {
      title: 'Готовится к выпуску новая модель киборгов',
      subject: 'Кибер-амазонки',
      text: [
        'В Эфире новости. Поступила информация о новой модели киборгов, планируемых к использованию в центрах загрузки сознания. Кибер-Амазонки - усовершенствованный кибер с выраженно женскими формами.',
        'Нам стало известно, что эта модель будет обладать не только женской грацией, но и весьма стервозным характером. Улучшенные алгоритмы поиска и дедукции помогут ей стать опасным охотником.',
      ],
      image: amazonImage,
    },
    failed_text: [
      'Эх видимо поручать тебе новобранцев все же не стоит. Миссию запороли. Результат прямо скажем неутешительный - а ну марш тренироваться!',
    ],
  },
  {
    url: 'https://adaptivelogic.diag.2035.university/index.js',
    uuid: '78cd34d6-c808-48be-a031-ebdf44c3ab4a',
    extension: 'https://my.2035.university/xapi/v1/results/adaptive-logic',
    name: 'Адаптивная логика',
    threshold: [{ title: 'Адаптивность логики', value: 6 }],
    description:
      'Я боялся этого момента, но он был неизбежен. Рано или поздно это должно было случиться. Киборг вызывает человека на поединок. 1 на 1. Позитронный мозг против человеческого. Оружием станет мышление. Примешь вызов? ',
    success: {
      title: 'Дуэль киборга и человека в области мышления',
      subject: 'Противостояние',
      text: [
        'Срочные новости. Впервые в современной истории киборг вызвал человека на “дуэль”. Vasil-20.35 один из наиболее продвинутых “Вознесенных” бросил вызов людям, предлложив соревнование в гибком мышлении.',
        'На вызов ответило сразу несколько представителей “Народного сопротивления”. Выиграть у машины, к сожалению, не удалось, но трое из пяти сыграли с киборгом вничью!  ',
      ],
      image: confrontationImage,
    },
    failed_text: [
      'Да похоже с киборгами бороться нам рановато… Может и не стоит тогда вовсе сопротивляться, если они не только сильнее, но и умнее нас? Может все зря?…          Нет! Я верю в нас “плотских”! Именно за нами будущее, а не за этими жестянками! Поэтому бегом готовиться к новой миссии!',
    ],
  },
];

export const getStatus = (activity: IActivity, result: any): StatusType | undefined => {
  // Определяем тест как KIOS
  if (activity.url.startsWith('https://kios')) {
    // Получаем порог
    const thresholds = activity.threshold;
    if (result) {
      // Проходимся по всем границам
      const current = thresholds.map(item => {
        // Находим схожую границу в результатах
        const scale = result.scales.find((scale: any) => {
          if (scale.selected !== undefined) {
            return scale.title === item.title && scale.selected;
          } else {
            return scale.title === item.title;
          }
        });

        if (scale) {
          // Сравниваем границу с результатом по шкале
          return item.less ? item.value >= scale.value : item.value <= scale.value;
        } else {
          return false;
        }
      });

      // Проверяем соответствие всем шкалам
      const isMatch = activity.thresholdOnlyOne
        ? current.some(item => item)
        : current.every(item => item);
      return isMatch ? 'passed' : 'failed';
    }
  }

  // Tap-Tap
  if (activity.uuid === 'dd6fff7d-0ca6-40d2-b8ea-d3126205dbbd') {
    const value = activity.threshold[0].value;
    if (result) {
      const current = result.Score;
      return current > value ? 'passed' : 'failed';
    }
  }

  // Адаптивная логика
  if (activity.uuid === '78cd34d6-c808-48be-a031-ebdf44c3ab4a') {
    // Получаем порог
    const threshold = activity.threshold[0].value;
    if (result) {
      const current = result.scoreAll;
      return current >= threshold ? 'passed' : 'failed';
    }
  }

  return;
};
