import styled from "styled-components";

import confirmModalImage from "../assets/confirm-modal.png";
import { Button } from "../common/Button";

const BaseInfoWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  position: absolute;
  left: 120px;
  top: 50px;
  background-image: url(${confirmModalImage});
  background-repeat: no-repeat;
  width: 771px;
  height: 412px;
  z-index: 2;
`;

const BaseInfoText = styled.div`
  position: absolute;
  top: 180px;
  left: 50px;
  width: 440px;
`;

const BaseInfoButtons = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 350px;
  left: 0;
  width: 100%;
  button {
    margin: 0 20px;
    font-size: 18px;
  }
`;
type BaseInfoProps = {
  description: string;
  onCancel: () => void;
  onOk: () => void;
};

function BaseInfo({ description, onCancel, onOk }: BaseInfoProps) {
  return (
    <BaseInfoWrapper isVisible={!!description}>
      <BaseInfoText>{description}</BaseInfoText>
      <BaseInfoButtons>
        <Button onClick={onOk}>Принять</Button>
        <Button onClick={onCancel}>Отложить</Button>
      </BaseInfoButtons>
    </BaseInfoWrapper>
  );
}

export default BaseInfo;
