import styled from "styled-components";
import { rangeLevel } from "../utils/rangeLevel";

type IndicatorLevelProps = {
  level: number;
};

const IndicatorWrapper = styled.div`
  display: flex;
`;

const IndicatorItem = styled.div<{ color: string; isActive: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ color }) => color};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
  margin-right: 5px;
`;

const colors = ["#f50303", "#f15703", "#f5cb03", "#8efb00", "#00ca1d"];

function IndicatorLevel({ level }: IndicatorLevelProps) {
  const natureLevel = rangeLevel(level, [1, 2, 3, 4, 5]);

  return (
    <IndicatorWrapper>
      {colors.map((color, i) => (
        <IndicatorItem
          key={color}
          color={color}
          isActive={i <= natureLevel - 1}
          title={level.toString()}
        />
      ))}
    </IndicatorWrapper>
  );
}

export default IndicatorLevel;
