export const dialogues = [
  "Меня зовут Майор Прохоров, и именно мне поручено возглавлять проект &laquo;Народное сопротивление&raquo;",
  "Вижу у тебя есть вопросы, но это все позже. Сначала покончим с формальностями. Для вступление в ряды бойцов сопротивления полагается пройти небольшое аудио-интервью.",
  "Твои ответы будет анализировать наш искусственный интеллект, поэтому отнесись серьезно! Порядок прост: я задаю вопрос, а ты нажимаешь кнопку записи и голосом проговариваешь ответ. Как закончишь - жмешь стоп. Если надо прослушать что получилось - жмешь Play. Вот и вся наука. Все понятно?",
];

export const fail_text =
  "Ну, начинается! Я же говорил, отнесись серьезно! Если сразу не понял вопрос, прочитай еще раз - потом нажми на кнопку записи и вперед. Мне нужны вдумчивые ответы - а не вот это вот. А ну марш на повторную запись!";

export const success_text =
  "Ну что же, результаты интервью выглядят вполне неплохо, поздравляю, новобранец, ты в деле! Пора отправляться в основной оперативный лагерь и приступать к выполнению тренировочных миссий. Или остались какие-то вопросы?";

export const explain_text =
  "Объясняю. Если ты еще не в курсе, город постепенно превращается в зону боевых действий. Силы правопорядка не справляются с выходками этих асоциальных недоумков, терминаторов недоделанных. Так называемые “Вознесенные”. как только загрузят свои мозги в машину, слетают с катушек и начинают творить всякие безобразия. И вот мы - народное сопротивление, пытаемся за счет скоординированных совместных действий все это прекратить. Теперь понятно? Тогда марш в тренировочный лагерь!";

export const mission_success = "Поздравляем! Вы прошли испытание с высоким результатом! Миссия выполнена успешно."

export const mission_fail = "К сожалению, ваш результат недостаточно высок для успешного похождения данной миссии."
