const lrs = "https://my.2035.university";

const environment = {
  LRSResultFormat: `${lrs}/xapi/v1/results/gametest-main`,
  proxyHost: "https://bypass.2035.university/api/activity",
  t: "c8d46d341bea4fd5bff866a65ff8aea9",
  production: true,
};

interface IframeParams {
  token: string;
  unti_id: string;
  activity_uuid: string;
}

class IntegrationService {
  public sendStart(params: IframeParams): Promise<Response> {
    return fetch(`${environment.proxyHost}/${params.activity_uuid}/start`, {
      body: this.buildPleBody(params),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public sendEnd(params: IframeParams): Promise<Response> {
    return fetch(`${environment.proxyHost}/${params.activity_uuid}/end`, {
      body: this.buildPleBody(params),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public sendResult(params: IframeParams, result: any): Promise<Response> {
    return fetch(`${environment.proxyHost}/${params.activity_uuid}/lrsdata`, {
      body: this.buildLrsBody(params, result),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public reportProgress(params: IframeParams, progress: number): Promise<Response> {
    return fetch(`${environment.proxyHost}/${params.activity_uuid}/progress`, {
      body: JSON.stringify({
        ...JSON.parse(this.buildPleBody(params)),
        progress: progress,
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public buildLrsBody(params: IframeParams, result: any): any {
    const extensions = {
      [environment.LRSResultFormat]: result,
    };

    return JSON.stringify({
      unti_id: params.unti_id,
      token: params.token,
      t: environment.t,
      extensions,
    });
  }

  public buildPleBody(params: IframeParams): any {
    return JSON.stringify({
      unti_id: params.unti_id,
      token: params.token,
      t: environment.t,
    });
  }
}

const integrationService = new IntegrationService();

export default integrationService;
