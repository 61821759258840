import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectAuth } from "../store/auth/auth.selector";

const ProtectedRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { isLoggedIn } = useSelector(selectAuth);

  // Проверяем авторизацию
  if (!isLoggedIn) return <Navigate to="/" />;

  return children;
};

export default React.memo(ProtectedRoute);
