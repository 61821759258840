import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

import { useAuth } from './hooks/use-auth.hooks';
import { Layout } from './common/Layout';
import { VideoCover } from './common/News';

import ProtectedRoute from './components/ProtectedRoute';

import Base from './pages/Base';
import Dialogue from './pages/Dialogue';
import Home from './pages/Home';
import Introduce from './pages/Introduce';
import NotFound from './pages/NotFound';
import SSORedirect from './pages/SSORedirect';
import InterviewProccess from './pages/InterviewProccess';
import InterviewSuccess from './pages/InterviewSuccess';
import GoodNews from './pages/GoodNews';
import BadNews from './pages/BadNews';
import Results from './pages/Results';
import TheEnd from './pages/TheEnd';
import MissionResult from './pages/MissionResult';

import introVideo from './assets/cover.mp4';

function App() {
  const videoRef = useRef<HTMLVideoElement>(null);

  const { isChecking } = useAuth();

  useEffect(() => {
    if (videoRef && videoRef.current) videoRef.current.playbackRate = 0.3;
  }, [videoRef]);

  return (
    <BrowserRouter>
      <VideoCover ref={videoRef} src={introVideo} isVisible={isChecking} autoPlay muted loop />
      <Layout isLoading={isChecking}>
        {!isChecking && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sso_redirect" element={<SSORedirect />} />
            <Route path="/introduce" element={<ProtectedRoute children={<Introduce />} />} />
            <Route path="/dialogue" element={<ProtectedRoute children={<Dialogue />} />} />
            <Route
              path="/questions/:page"
              element={<ProtectedRoute children={<InterviewProccess />} />}
            />
            <Route
              path="/dialogue/success"
              element={<ProtectedRoute children={<InterviewSuccess />} />}
            />
            <Route path="/base" element={<ProtectedRoute children={<Base />} />} />
            <Route path="/mission/:status/:uuid" element={<MissionResult />} />
            <Route path="/success/:uuid" element={<ProtectedRoute children={<GoodNews />} />} />
            <Route path="/fail/:uuid" element={<ProtectedRoute children={<BadNews />} />} />
            <Route path="/results" element={<Results />} />
            <Route path="/end" element={<TheEnd />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Layout>
    </BrowserRouter>
  );
}

export default App;
