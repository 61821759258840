import {useNavigate, useParams} from "react-router-dom";

import {
    DialogueButton,
    DialogueButtonWrapper,
    DialogueIcon,
    DialogueText,
    DialogueMissionContent,
    DialogueMissionFrame
} from "../common/Dialogue";
import { mission_success, mission_fail } from "../utils/dialogues";

import React from "react";

function MissionResult() {
    const { status, uuid } = useParams<any>();
    const navigate = useNavigate();

    const handleGo = () => {
        if(status === 'success') {
            navigate(`/success/${uuid}`);
        } else {
            navigate(`/fail/${uuid}`);
        }
    }

    return (
        <DialogueMissionContent>
            <DialogueIcon status={status!} />
            <DialogueMissionFrame>
                <DialogueText
                    dangerouslySetInnerHTML={{ __html: status === 'success' ? mission_success : mission_fail }}
                />
                <DialogueButtonWrapper>
                    <DialogueButton onClick={handleGo}>Дальше</DialogueButton>
                </DialogueButtonWrapper>
            </DialogueMissionFrame>
        </DialogueMissionContent>
    );
}

export default MissionResult;
