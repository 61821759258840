import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import defaultButtonImage from '../assets/default-button.png';
import activeButtonImage from '../assets/active-button.png';
import disabledButtonImage from '../assets/disabled-button.png';

const TheEndWrapper = styled.div`
  height: 600px;
  overflow: hidden;
  position: relative;
`;

const TheEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 1rem;
  width: 100%;
  transition: 0.3s;
  transform: translateY(680px);
`;

const TheEndItem = styled.div`
  margin: 2rem 0 1rem;
  text-align: center;
  font-size: 1.3rem;
`;

const TheEndItemPosition = styled.div`
  color: #5061dc;
`;

const TheEndItemPerson = styled.div`
  /* ... */
`;

const TheEndButton = styled.a`
  display: table;
  margin: 0 auto;
  background-image: url(${defaultButtonImage});
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 136px;
  height: 36px;
  border: none;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  line-height: 36px;
  margin-top: 20px;
  &:hover {
    background-image: url(${activeButtonImage});
    color: #fff;
  }
  &:disabled {
    background-image: url(${disabledButtonImage});
  }
`;

const people = [
  { position: 'Автор идеи', person: ['Андрей Комиссаров'] },
  {
    position: 'Гейм дизайн',
    person: ['Андрей Комиссаров'],
  },
  { position: 'Дизайн нарратива', person: ['Андрей Комиссаров'] },
  { position: 'Художник', person: ['Роман Береговский'] },
  { position: 'Дизайн, верстка, монтаж видео', person: ['Татьяна Кулагина'] },
  {
    position: 'Команда разработки',
    person: ['Дмитрий Самойлов', 'Константин Чуйков', 'Александр Серкин', 'Артем Павлюткин'],
  },
  {
    position: 'Команда диагностики',
    person: ['Марина Чурикова', 'Татьяна Чиркина', 'Ангелина Кучина'],
  },
];

function TheEnd() {
  const theEndContainer = useRef<HTMLDivElement>(null);
  const [theEndFinished, setTheEndFinished] = useState(false);

  useEffect(() => {
    if (theEndContainer?.current) {
      let a = 600;
      setInterval(() => {
        a -= 1;
        theEndContainer!.current!.style.transform = `translateY(${a}px)`;
      }, 20);
    }
  }, [theEndContainer]);

  return (
    <>
      {!theEndFinished && (
        <TheEndWrapper>
          <TheEndContainer ref={theEndContainer}>
            {people.map(item => (
              <TheEndItem key={item.position}>
                <TheEndItemPosition>{item.position}</TheEndItemPosition>
                {item.person.map(name => (
                  <TheEndItemPerson key={name}>{name}</TheEndItemPerson>
                ))}
              </TheEndItem>
            ))}
          </TheEndContainer>
        </TheEndWrapper>
      )}
      <TheEndButton href="https://testme.2035.university/">Завершить</TheEndButton>
    </>
  );
}

export default TheEnd;
