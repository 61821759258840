import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchQuestions } from "../store/questions/questions.reducer";
import DialogueWrapper from "../components/DialogueWrapper";
import { dialogues } from "../utils/dialogues";
import { QuestionAPI } from "../services/questions.services";

function Dialogue() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogIndex, setDialogIndex] = useState(0);

  useEffect(() => {
    (async () => {
      const answers = await QuestionAPI.fetchAnswers();
      if (answers.length > 0) {
        navigate("/base");
      }
    })();
  }, [navigate]);

  useEffect(() => {
    dispatch(fetchQuestions());
  }, [dispatch]);

  const handleClick = useCallback(() => {
    if (dialogIndex < 2) {
      setDialogIndex((prev) => prev + 1);
    } else {
      navigate("/questions/0");
    }
  }, [navigate, dialogIndex]);

  const dialog = dialogues[dialogIndex];

  return <DialogueWrapper text={dialog} onClick={handleClick} dialogIndex={dialogIndex} />;
}

export default Dialogue;
