import styled from "styled-components";

import disabledButtonImage from "../assets/disabled-button.png";
import activeButtonImage from "../assets/active-button.png";
import defaultButtonImage from "../assets/default-button.png";

export const Button = styled.button`
  background-image: url(${defaultButtonImage});
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 136px;
  height: 36px;
  border: none;
  color: #122d59;
  cursor: pointer;
  &:hover {
    background-image: url(${activeButtonImage});
    color: #fff;
  }
  &:disabled {
    background-image: url(${disabledButtonImage});
  }
`;
