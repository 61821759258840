import React from "react";
import styled from "styled-components";

const NewsBlockWrapper = styled.div`
  position: absolute;
  top: 45px;
  left: 632px;
  width: 326px;
  border: 1px solid rgba(101, 223, 247, 1);
`;

const NewsBlockImage = styled.div<{ background: string }>`
  width: 100%;
  height: 213px;
  background-color: #fff;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const NewsBlockHeader = styled.div`
  width: 100%;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  font-size: 18px;
  text-transform: uppercase;
`;

const NewsBlockTitle = styled.div`
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 186, 255, 1) 0%, rgba(101, 223, 247, 1) 100%);
  color: #1a0e00;
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-transform: uppercase;
`;

type NewsBlockProps = {
  bg: string;
  text: string;
};

const NewsBlock: React.FC<NewsBlockProps> = ({ bg, text }) => {
  return (
    <NewsBlockWrapper>
      <NewsBlockImage background={bg} />
      <NewsBlockHeader>Тема дня</NewsBlockHeader>
      <NewsBlockTitle>{text}</NewsBlockTitle>
    </NewsBlockWrapper>
  );
};

export default React.memo(NewsBlock);
