import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectActivityById } from "../store/activities/activities.selector";

import NewsBlock from "../components/NewsBlock";
import Leading from "../components/Leading";
import { Button, Credit, IntroduceWrapper, Title, UrgentNews } from "../common/News";

import urgentNewsImage from "../assets/urgent-news.png";
import defaultImage from "../assets/img1.jpg";

function GoodNews() {
  const navigate = useNavigate();
  const { uuid } = useParams<"uuid">();
  const activity = useSelector(selectActivityById(uuid));
  const [creditIndex, setCreditIndex] = useState(0);

  const text = activity?.success.text || [];
  const image = activity?.success.image || defaultImage;
  const subject = activity?.success.subject || "";
  const title = activity?.success.title || "";

  const handleClick = useCallback(() => {
    if (text.length === creditIndex + 1) {
      navigate("/base");
    } else {
      setCreditIndex((i) => i + 1);
    }
  }, [text.length, creditIndex, navigate]);

  return (
    <IntroduceWrapper>
      <Credit>
        <p dangerouslySetInnerHTML={{ __html: text[creditIndex] }} />
      </Credit>
      <Leading emotion="default" />
      <NewsBlock bg={image} text={subject} />
      <Title>{title}</Title>
      <Button onClick={handleClick}>
        {text.length === creditIndex + 1 ? "Продолжить" : "Смотреть далее"}
      </Button>
      <UrgentNews src={urgentNewsImage} alt="Срочные новости" />
    </IntroduceWrapper>
  );
}

export default GoodNews;
