const minMax = (arr: number[]) => {
  const orderedNums = arr.slice().sort((a, b) => a - b);
  return { min: orderedNums[0], max: orderedNums[orderedNums.length - 1] };
};

export const centroid = (points: number[]) => {
  // Если количество точек нечетное, значит точки переданы некорректно
  if (points.length % 2 !== 0) throw new Error("Incorrect points");
  // Получаем все координаты по Х
  const xs = points.filter((_, i) => i % 2 === 0);
  // Получаем все координаты по Y
  const ys = points.filter((_, i) => i % 2 !== 0);
  // Получаем min/max по Х
  const x_range = minMax(xs);
  // Получаем min/max по Y
  const y_range = minMax(ys);
  // Считаем средние координаты
  const x = x_range.min + (x_range.max - x_range.min) / 2;
  const y = y_range.min + (y_range.max - y_range.min) / 2;

  return { x, y };
};
