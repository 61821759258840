import styled from "styled-components";

import testingFrameImage from "../assets/testing-frame.png";
import mentorImage from "../assets/mentor2.png";
import disabledButtonImage from "../assets/disabled-button.png";
import activeButtonImage from "../assets/active-button.png";

export const InterviewWrapper = styled.div<{ vertical?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  justify-content: center;
  align-items: center;
`;
export const InterviewContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-image: url(${testingFrameImage});
  background-repeat: no-repeat;
  width: 1001px;
  height: 447px;
  padding: 60px 50px 20px 70px;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
`;

export const InterviewHeader = styled.div`
  width: 100%;
  padding: 30px 40px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const InterviewFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin: 0 2rem 1rem;
  button {
    margin: 0 1rem 1rem 0;
    font-size: 15px;
  }
`;

export const InterviewTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #98a4bc;
`;
export const InterviewQuestionText = styled.div`
  width: 380px;
`;

export const InterviewMentor = styled.div`
  background-image: url(${mentorImage});
  background-size: 90%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 250px;
  width: 300px;
  height: 100%;
`;

export const InterviewButton = styled.button`
  background-image: url(${activeButtonImage});
  background-color: transparent;
  background-repeat: no-repeat;
  width: 122px;
  height: 32px;
  border: none;
  color: #59dbf9;
  cursor: pointer;
  &:disabled {
    background-image: url(${disabledButtonImage});
  }
`;

export const InterviewQuestionWrapper = styled.div``;

export const Smile = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
`;
