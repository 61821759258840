import styled from "styled-components";

import coverImage from "../assets/cover.jpg";
export const Layout = styled.div<{ isLoading: boolean }>`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  position: relative;
  &::after {
    display: block;
    opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
    min-height: ${({ isLoading }) => (isLoading ? "680px" : "0")};
    width: 100%;
    background-image: url("${coverImage}");
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.3);
    transition: opacity 1s linear;
    z-index: 5;
  }
`;
