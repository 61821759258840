import { useCallback, useEffect, useMemo } from "react";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { useVoiceRecorder } from "../../hooks/use-voice-recorder.hooks";

import {
  InterviewContent,
  InterviewMentor,
  InterviewQuestionText,
} from "../../pages/interview.styled";
import { IQuestion, setAnswer } from "../../store/questions/questions.reducer";
import InterviewStartButton from "./InterviewStartButton";
import {
  InterviewButton,
  InterviewButtonsWrapper,
  InterviewDuration,
} from "./interview_buttons.styled";
import FilesAPI from "../../services/files.services";
import { fail_text } from "../../utils/dialogues";

type InterviewQuestionProps = {
  question: IQuestion;
};

const InterviewQuestion: React.FC<InterviewQuestionProps> = ({ question }) => {
  const dispatch = useDispatch();

  const timeLimit = 120;
  const {
    duration,
    analyser,
    statusRecording,
    startRecording,
    stopRecording,
    clearRecording,
    isPlaying,
    startPlaying,
    stopPlaying,
  } = useVoiceRecorder(
    (data, _, duration) => {
      saveAnswer(data);
    },
    {
      timeLimit,
      enableByteMonitor: true,
    }
  );

  /**
   * Добавляем новый ответ
   */
  const saveAnswer = useCallback(
    async (blob: Blob) => {
      try {
        // загружаем файл на сервер
        const file = await FilesAPI.upload(`Recording for ${question.id}`, blob);
        if (file) {
          // Записываем ответ
          dispatch(setAnswer({ id: question.id, answer: file.id, duration }));
        }
      } catch (error) {}
    },
    [dispatch, duration, question.id]
  );

  /**
   * Запускаем запись звука с микрофона
   */
  const handleStartRecording = () => {
    dispatch(setAnswer({ id: question.id, answer: undefined, duration: 0 }));
    startRecording();
  };

  // Оставшееся время записи
  const timeRecording = useMemo(() => {
    const time = new Date();
    time.setHours(0, 0, timeLimit, 0);
    time.setSeconds(time.getSeconds() - duration);
    return `${("0" + time.getMinutes()).slice(-2)}:${("0" + time.getSeconds()).slice(-2)}`;
  }, [duration]);

  /**
   * Если изменился айди вопроса, чистим запись и статус
   */
  useEffect(() => {
    if (question.id)
      return () => {
        clearRecording();
      };
  }, [question.id, clearRecording]);

  const url = process.env.REACT_APP_BACKEND + "/download/" + question.answer;
  const question_text = statusRecording === "stopped" && duration > 120 ? fail_text : question.text;

  return (
    <InterviewContent>
      <InterviewQuestionText dangerouslySetInnerHTML={{ __html: question_text }} />
      <InterviewMentor />
      <InterviewButtonsWrapper>
        <InterviewStartButton
          onStart={handleStartRecording}
          status={statusRecording}
          onStop={stopRecording}
          analyser={analyser}
        />
        <InterviewDuration>Осталось {timeRecording}</InterviewDuration>
        {isPlaying ? (
          <InterviewButton onClick={() => stopPlaying()}>
            <PauseCircleOutlined /> Остановить
          </InterviewButton>
        ) : (
          <InterviewButton onClick={() => startPlaying(url)} disabled={!question.answer}>
            <PlayCircleOutlined color="#59dbf9" /> Прослушать
          </InterviewButton>
        )}
      </InterviewButtonsWrapper>
    </InterviewContent>
  );
};

export default InterviewQuestion;
