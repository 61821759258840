import { AudioOutlined, UndoOutlined, LoadingOutlined } from "@ant-design/icons";
import { RecordingStatus } from "../../hooks/use-voice-recorder.hooks";
import InterviewDynamicIcon from "./InterviewDynamicIcon";

import { InterviewButton, InterviewRecording } from "./interview_buttons.styled";
type InterviewStartButtonProps = {
  status: RecordingStatus;
  onStart: () => void;
  onStop: () => void;
  analyser: AnalyserNode | null;
};

const InterviewStartButton: React.FC<InterviewStartButtonProps> = ({
  status,
  onStart,
  onStop,
  analyser,
}) => {
  switch (status) {
    case "ready":
      return (
        <InterviewButton primary onClick={onStart}>
          <AudioOutlined /> Начать запись
        </InterviewButton>
      );
    case "recording":
      return (
        <InterviewButton onClick={onStop}>
          <InterviewRecording>Идет запись</InterviewRecording>
          {analyser && <InterviewDynamicIcon analyser={analyser} />}
          Стоп
        </InterviewButton>
      );
    case "stopped":
      return (
        <InterviewButton primary onClick={onStart}>
          <UndoOutlined /> Записать снова
        </InterviewButton>
      );
    default:
      return (
        <InterviewButton>
          <LoadingOutlined /> Выполняю...
        </InterviewButton>
      );
  }
};

export default InterviewStartButton;
