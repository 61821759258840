import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { selectActivityById } from "../store/activities/activities.selector";
import {
  DialogueButton,
  DialogueButtonWrapper,
  DialogueContent,
  DialogueFrame,
  DialogueMentor,
  DialogueText,
} from "../common/Dialogue";

import angryMentorImage from "../assets/angry.png";

function BadNews() {
  const navigate = useNavigate();
  const { uuid } = useParams<"uuid">();
  const activity = useSelector(selectActivityById(uuid));
  const [dialogIndex, setDialogIndex] = useState(0);

  const text = activity?.failed_text || [];

  const handleClick = useCallback(() => {
    if (text.length === dialogIndex + 1) {
      navigate(`/base`);
    } else {
      setDialogIndex((prev) => prev + 1);
    }
  }, [text.length, dialogIndex, navigate]);

  return (
    <DialogueContent>
      <DialogueMentor>
        <img src={angryMentorImage} alt="" width={360} />
      </DialogueMentor>
      <DialogueFrame>
        <DialogueText dangerouslySetInnerHTML={{ __html: text[dialogIndex] }} />
        <DialogueButtonWrapper>
          <DialogueButton onClick={handleClick}>
            {text.length === dialogIndex + 1 ? "Вернуться на базу" : "Дальше"}
          </DialogueButton>
        </DialogueButtonWrapper>
      </DialogueFrame>
    </DialogueContent>
  );
}

export default BadNews;
