import {
  DialogueButton,
  DialogueButtonWrapper,
  DialogueContent,
  DialogueFrame,
  DialogueMentor,
  DialogueText,
} from "../common/Dialogue";

import mentorImage from "../assets/mentor.png";
import { dialogues } from "../utils/dialogues";

type DialogueWrapperProps = {
  text: string;
  dialogIndex: number;
  onClick: () => void;
};

function DialogueWrapper({ text, dialogIndex, onClick }: DialogueWrapperProps) {
  return (
    <DialogueContent>
      <DialogueMentor>
        <img src={mentorImage} alt="" width={360} />
      </DialogueMentor>
      <DialogueFrame>
        <DialogueText dangerouslySetInnerHTML={{ __html: text }} />
        <DialogueButtonWrapper>
          <DialogueButton onClick={onClick}>
            {dialogues.length === dialogIndex + 1 ? "Приступить к интервью" : "Дальше"}
          </DialogueButton>
        </DialogueButtonWrapper>
      </DialogueFrame>
    </DialogueContent>
  );
}

export default DialogueWrapper;
