import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import finishBase from '../assets/result-bg.jpg';
import { DialogueButton } from '../common/Dialogue';
import IndicatorLevel from '../components/IndicatorLevel';

import { ActivityAPI } from '../services/activities.services';
import integrationService from '../services/integration.services';
import { fetchActivities } from '../store/activities/activities.reducer';
import { selectActivities } from '../store/activities/activities.selector';
import { selectAuth } from '../store/auth/auth.selector';
import { rangeLevel } from '../utils/rangeLevel';

type SummaryType = {
  [key: string]: {
    low: string;
    mid: string;
    high: string;
  };
};

let summary: SummaryType = {
  'Коммуникация и кооперация в цифровой среде': {
    low: `У Вас низкие показатели по компетенции &laquo;Коммуникация и кооперация в цифровой среде&raquo;. Иногда Вам стоит более внимательно подходить к выбору цифровых средств для взаимодействия с другими людьми.`,
    mid: `У Вас средние показатели по компетенции &laquo;Коммуникация и кооперация в цифровой среде&raquo;. Вы умеете пользоваться цифровыми средствами для достижения поставленных целей во взаимодействии с другими людьми, однако иногда у Вас могут возникнуть с этим проблемы.`,
    high: `У Вас высокие показатели по компетенции &laquo;Коммуникация и кооперация в цифровой среде&raquo;. Вы грамотно используете цифровые средства для достижния поставленных целей во взаимодействии с другими людьми.`,
  },
  'Саморазвитие в условиях неопределенности': {
    low: `У Вас низкие показатели по компетенции "Саморазвитие в условиях неопределенности". Иногда Вам стоит более тщательно продумывать цели и подбирать способы решения жизненных задач.`,
    mid: `У Вас средние показатели по компетенции "Саморазвитие в условиях неопределенности". Вы ставите цели и умеете подбирать способы решения жизненных задач, но иногда с этим у Вас возникают затруднения.`,
    high: `У Вас высокие показатели по компетенции "Саморазвитие в условиях неопределенности. Вы ставите четкие цели и грамотно подбираете способы решения жизненных задач.`,
  },
  'Управление информацией и данными': {
    low: `У Вас низкие показатели по компетенции "Управление информацией и данными".Иногда Вам стоит более тщательно искать нужную информацию. Вы редко используете необходимые данные при решении задач.`,
    mid: `У Вас средние показатели по компетенции "Управление информацией и данными". Вы умеете искать, анализировать и запоминанть необходимую информацию, но не всегда используете необходимые данные при решении задач.`,
    high: `У Вас высокие показатели по компетенции "Управлением информацией и данными". Вы умеете находить и анализировать необходимые источники информации, а также запоминать и использовать нужные данные при решении задач.`,
  },
  'Креативное мышление': {
    low: `У Вас низкие показатели по компетенции "Креативное мышление". Иногда Вам стоит перестраивать сложившиеся способы решения задач, выдвигать альтернативные варианты действий.`,
    mid: `У Вас средние показатели по компетенции "Креативное мышление". Вы умеете генерировать новые идеи, однако не всегда готовы перестраивать сложившиеся способы решения задач и выдвигать альтернативные варианты действий.`,
    high: `У Вас высокие показатели по компетенции "Креативное мышление". Вы умеете генерировать новые идеи и готовы абстрагироваться от стандартных моделей: перестраивать сложившиеся способы решения задач и выдвигать альтернативные варианты действий.`,
  },
  'Критическое мышление в цифровой среде': {
    low: `У Вас низкие показатели по компетенции "Критическое мышление в цифровой среде". Иногда Вам стоит более тщательно оценивать достоверность информации и больше опираться на данные при построении логических умозаключений.`,
    mid: `У Вас средние показатели по компетенции "Критическое мышление в цифровой среде". Вы умеете оценивать поступающую информацию на достоверность, однако можете испытывать затруднения с построением логических умозаключений на основании поступающих данных.`,
    high: `У Вас высокие показатели по компетенции "Критическое мышление в цифровой среде". Вы умеете оценивать поступающую информацию на достоверность, строить логические умозаключении на основании поступающих данных.`,
  },
};

const ResultWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 680px;
  background-image: url(${finishBase});
  overflow: hidden;
`;

const ResultTable = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 300px;
  grid-template-rows: repeat(5, 1fr);
  width: 950px;
  margin: 350px auto 0;
`;

const ResultBlock = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
  border: 1px solid #27c3f5;
  padding: 8px 12px;
  font-size: 18px;
`;

const ResultBlockItem = styled(ResultBlock)<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? '#27c9fb' : '#fff')};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const ResultBlockDescription = styled(ResultBlock)`
  font-size: 16px;
  background: rgb(27, 56, 106);
  background: linear-gradient(0deg, rgba(27, 56, 106, 1) 0%, rgba(4, 29, 64, 1) 100%);
`;

const ResultButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ResultButton = styled(DialogueButton)``;

type ScaleType = { title: string; value: number };

function Results() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activities = useSelector(selectActivities);
  const { unti_id } = useSelector(selectAuth);
  const [activeIndicator, setActiveIndicator] = useState(
    'Коммуникация и кооперация в цифровой среде'
  );
  const [indicators, setIndicators] = useState<{ [key: string]: number }>({});

  // Обновляем список активностей
  useEffect(() => {
    dispatch(fetchActivities());
  }, [dispatch]);

  // Уходим обратно если не все активности заполнены
  useEffect(() => {
    const finishedCount = activities.filter(activity => !!activity.status);
    console.log('isFinished :>> ', finishedCount);
    if (finishedCount) {
      //navigate("/base");
    }
  }, [activities, navigate]);

  // Получаем результаты всех активностей
  useEffect(() => {
    if (!activities) return;
    (async () => {
      const data = await ActivityAPI.fetch(unti_id);

      // Получаем все шкалы и их значения
      const scales = activities.reduce<ScaleType[]>((acc: any, item) => {
        const scale = data[item.uuid]?.extensions[item.extension];
        if (scale) {
          // Тап-тап не входит в результат
          if (item.uuid === 'dd6fff7d-0ca6-40d2-b8ea-d3126205dbbd') {
            return acc;
          }
          // Адаптивную логику достаем отдельно
          if (item.uuid === '78cd34d6-c808-48be-a031-ebdf44c3ab4a') {
            return acc.concat({ title: 'Адаптивность логики', value: scale.scoreAll });
          }
          // У Брунера берем только креативность и символическое мышление
          if (item.uuid === '27befc35-cac8-42b1-b0ed-096803ee3758') {
            return acc.concat(
              scale.scales
                .map((s: any) => ({ title: s.title, value: s.value }))
                .filter(
                  (s: any) => s.title === 'Креативность' || s.title === 'Символическое мышление'
                )
            );
          }
          // Все остальное достаем из шкал
          return acc.concat(
            scale.scales.map((s: any) => {
              return { title: s.title, value: s.value };
            })
          );
        } else {
          return acc;
        }
      }, []);

      // Переводим шкалы в систему оценки
      const levels = scales.reduce<{ [key: string]: number }>((acc, scale: ScaleType) => {
        if (scale.title === 'Ригидность') {
          acc[scale.title] = rangeLevel(scale.value, [33, 42, 46, 50, 78]);
        }
        if (scale.title === 'Толерантность к неопределенности') {
          acc[scale.title] = rangeLevel(scale.value, [12, 19, 22, 25, 42]);
        }
        if (scale.title === 'Достижение долгосрочных целей') {
          acc[scale.title] = rangeLevel(scale.value, [12, 14, 22, 29, 50]);
        }
        if (scale.title === 'Креативность') {
          acc[scale.title] = rangeLevel(scale.value, [2, 5, 7, 9, 15]);
        }

        if (scale.title === 'Символическое мышление') {
          acc[scale.title] = rangeLevel(scale.value, [2, 5, 7, 9, 15]);
        }

        if (scale.title === 'Взаимодействие с командой') {
          acc[scale.title] = rangeLevel(scale.value, [1, 2, 4, 5, 8]);
        }

        if (scale.title === 'Адаптивность логики') {
          acc[scale.title] = rangeLevel(scale.value, [2, 5, 9, 12, 21]);
        }

        return acc;
      }, {});

      const result = Object.keys(summary).reduce<{ [key: string]: number }>((acc, item) => {
        if (item === 'Коммуникация и кооперация в цифровой среде') {
          acc[item] = levels['Взаимодействие с командой'];
        }

        if (item === 'Саморазвитие в условиях неопределенности') {
          const uncertanity_result =
            0.5 * levels['Ригидность'] + 0.5 * levels['Толерантность к неопределенности'];
          acc[item] = 0.6 * levels['Достижение долгосрочных целей'] + 0.4 * uncertanity_result;
        }

        if (item === 'Управление информацией и данными') {
          acc[item] = levels['Навыки ИКТ'] || 1;
        }

        if (item === 'Креативное мышление') {
          acc[item] = levels['Креативность'];
        }

        if (item === 'Критическое мышление в цифровой среде') {
          acc[item] = 0.6 * levels['Адаптивность логики'] + 0.4 * levels['Символическое мышление'];
        }

        return acc;
      }, {});

      setIndicators(result);

      integrationService.sendResult(
        {
          token: '',
          unti_id,
          activity_uuid: 'ee96ca51-55ae-43da-beb5-01f68e7563df',
        },
        {
          scales,
          levels,
          result,
        }
      );
    })();
  }, [activities, unti_id]);

  const interpretation = useMemo(() => {
    const value = indicators[activeIndicator];

    if (value >= 1 && value < 3) {
      return summary[activeIndicator].low;
    } else if (value >= 3 && value <= 4) {
      return summary[activeIndicator].mid;
    } else if (value > 4) {
      return summary[activeIndicator].high;
    } else {
      return '';
    }
  }, [indicators, activeIndicator]);

  const handleGoEnd = () => navigate(`/end`);

  return (
    <ResultWrapper>
      <ResultTable>
        <ResultBlockDescription
          area="1 / 5 / 6 / 6"
          dangerouslySetInnerHTML={{ __html: interpretation }}
        />
        {Object.keys(indicators).map((item, i) => (
          <ResultBlockItem
            key={item}
            isActive={activeIndicator === item}
            area={`${i + 1} / 1 / ${i + 2} / 5`}
            onClick={() => setActiveIndicator(item)}
          >
            {item}
            <IndicatorLevel level={indicators[item]} />
          </ResultBlockItem>
        ))}
      </ResultTable>
      <ResultButtonWrapper>
        <ResultButton onClick={handleGoEnd}>Далее</ResultButton>
      </ResultButtonWrapper>
    </ResultWrapper>
  );
}

export default Results;
