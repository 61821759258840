import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "../store/auth/auth.selector";

export const AUTH_URL = `${process.env.REACT_APP_SSO_URL}?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SSO_REDIRECT}&response_type=code`;

function Home() {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(selectAuth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/introduce");
    } else {
      if (process.env.NODE_ENV === "development") {
        navigate("/sso_redirect?code=localhost");
      } else {
        window.location.href = AUTH_URL;
      }
    }
  }, [isLoggedIn, navigate]);

  return null;
}

export default Home;
