import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./app/app.reducer";
import authReducer from "./auth/auth.reducer";
import activitiesReducer from "./activities/activities.reducer";
import questionsReducer from "./questions/questions.reducer";

export const middleware = (getDefaultMiddleware: any) => {
  return getDefaultMiddleware({ immutableCheck: true, thunk: true });
};

export const reducer = {
  app: appReducer,
  questions: questionsReducer,
  auth: authReducer,
  activities: activitiesReducer,
};

export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
