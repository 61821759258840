type RangeOptions = {
  reverse?: boolean;
  useIndex?: boolean;
};

export const rangeLevel = (current: number, range: number[], options?: RangeOptions) => {
  const nums = options?.reverse ? range.slice().reverse() : range;
  const index = nums.findIndex((item) => current <= item);

  return options?.useIndex ? index : index + 1;
};
