import axios from "axios";

export type ActivitiesType = {
  [key: string]: any;
};

const fetch = async (unti_id: string): Promise<ActivitiesType> => {
  try {
    const response = await axios.get(`https://bypass.2035.university/api/gametest/${unti_id}`);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось загрузить список активностей");
  }
};

export const ActivityAPI = { fetch };
