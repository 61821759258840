import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'react-redux';

import './scss/index.scss';

import App from './App';

import { store } from './store';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND;

axios.interceptors.request.use(config => {
  const token = store.getState().auth.token;
  if (token) {
    config.headers!.Authorization = token;
  }
  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
