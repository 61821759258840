import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  DialogueButton,
  DialogueButtonWrapper,
  DialogueContent,
  DialogueFrame,
  DialogueMentor,
  DialogueText,
} from "../common/Dialogue";
import { explain_text, success_text } from "../utils/dialogues";

import mentorImage from "../assets/mentor.png";

function InterviewSuccess() {
  const navigate = useNavigate();
  const [isNeedExplain, setIsNeedExplain] = useState(false);

  const handleGo = () => navigate("/base");
  const handleHelp = () => setIsNeedExplain(true);

  return (
    <DialogueContent>
      <DialogueMentor>
        <img src={mentorImage} alt="" width={360} />
      </DialogueMentor>
      <DialogueFrame>
        <DialogueText
          dangerouslySetInnerHTML={{ __html: isNeedExplain ? explain_text : success_text }}
        />
        <DialogueButtonWrapper>
          <DialogueButton onClick={handleGo}>Приступить к тренировкам</DialogueButton>
          {!isNeedExplain && (
            <DialogueButton onClick={handleHelp}>Узнать подробности</DialogueButton>
          )}
        </DialogueButtonWrapper>
      </DialogueFrame>
    </DialogueContent>
  );
}

export default InterviewSuccess;
