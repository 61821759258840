import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AuthAPI } from "../services/auth.services";
import { login } from "../store/auth/auth.reducer";
/**
 * Хук проверки авторизации
 */
export const useAuth = () => {
  const dispatch = useDispatch();

  // Состояние проверки, по умолчанию включено
  const [isChecking, setIsChecking] = useState(true);

  // Асинхронная функция проверки
  const verifyAuth = useCallback(async () => {
    // Получаем токен
    const token = localStorage.getItem("gametest-token");

    if (token) {
      // Если токен есть, получаем данные для юзера
      const auth = await AuthAPI.whoami(token);

      // Если данные получены, записываем в стейт
      if (auth) {
        dispatch(login({ ...auth, token }));
      }
    }
    // В любом случае отключаем проверку
    setTimeout(() => {
      setIsChecking(false);
    }, 3000);
  }, [dispatch]);

  useEffect(() => {
    verifyAuth();
  }, [verifyAuth]);

  return {
    isChecking,
  };
};
