import { IQuestion } from "../../store/questions/questions.reducer";

import {
  InterviewProgressBar,
  InterviewProgressLine,
  InterviewProgressLineItem,
  InterviewProgressMarks,
  InterviewProgressWrapper,
} from "./interview_progress.styled";

type InterviewProgressProps = {
  questions: IQuestion[];
};

const InterviewProgress: React.FC<InterviewProgressProps> = ({ questions }) => {
  const isActive = (item: IQuestion) => {
    return !!item.answer && item.duration !== undefined && item.duration >= 0;
  };

  return (
    <InterviewProgressWrapper>
      <InterviewProgressBar>
        <InterviewProgressMarks>
          <span>0%</span>
          <span>Прогресс</span>
          <span>100%</span>
        </InterviewProgressMarks>
        <InterviewProgressLine role="progressbar">
          {questions.map((item) => (
            <InterviewProgressLineItem key={item.id} active={isActive(item)} />
          ))}
        </InterviewProgressLine>
      </InterviewProgressBar>
    </InterviewProgressWrapper>
  );
};

export default InterviewProgress;
