import { Circle, Group, Text, Image } from "react-konva";
import useImage from "use-image";

import checkImage from "../assets/check.png";

type PolygonCircleProps = {
  x: number;
  y: number;
  num: string;
  status?: "passed" | "failed";
};

const Check = () => {
  const [image] = useImage(checkImage);
  return <Image image={image} width={24} height={24} offsetX={10} offsetY={12} />;
};

function PolygonCircle({ x, y, num, status }: PolygonCircleProps) {
  return (
    <Group x={x} y={y} width={20} height={20}>
      <Circle
        radius={20}
        fill={status ? "#00c52e" : "#4a2f00"}
        stroke={status ? "#277ffd" : "#ffa60c"}
        strokeWidth={3}
      />
      <Circle radius={16} stroke={status ? "#24085e" : "#1b1100"} strokeWidth={5} />
      {status ? (
        <Check />
      ) : (
        <Text text={num} fill="#ffa60c" fontSize={20} offsetX={5} offsetY={8} />
      )}
    </Group>
  );
}

export default PolygonCircle;
