import axios from "axios";
import { IQuestion } from "../store/questions/questions.reducer";

const fetch = async (): Promise<IQuestion[]> => {
  try {
    const response = await axios.get<IQuestion[]>(`/questions`);
    return response.data;
  } catch (error) {
    throw new Error("Токен пользователя не прошел проверку");
  }
};

type Answer = {
  question_id: string;
  file_id?: string;
};

const saveAnswers = async (answers: Answer[]) => {
  try {
    const response = await axios.post(`/answers`, { answers });
    return response.data;
  } catch (error) {
    throw new Error("Не удалось отправить ответы. Повторите попытку.");
  }
};

const fetchAnswers = async () => {
  try {
    const response = await axios.get<Answer[]>(`/answers`);
    return response.data;
  } catch (error) {
    throw new Error("Не удалось получить ответы. Повторите попытку.");
  }
};

export const QuestionAPI = { fetch, saveAnswers, fetchAnswers };
