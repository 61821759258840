import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";

export const subjects = ["Вознесённые", "Преступления киборгов", "Народное сопротивление"];

type CreditType = {
  subject: string;
  img: string;
  button: string;
  text: string;
  emotions: 'default' | 'handup';
  title: string;
};

export const credits: CreditType[] = [
  {
    subject: subjects[0],
    img: img1,
    button: "Смотреть далее",
    text: "В эфире вечерние новости. Как сообщают источники, пожелавшие остаться неизвестными, в городе открылся еще один <b>центр загрузки сознания</b>. Это уже пятый такой центр за последний год.",
    emotions: 'default',
    title: 'Открылся центр загрузки сознания'
  },
  {
    subject: subjects[0],
    img: img1,
    button: "Смотреть далее",
    text: "<b>Полная загрузка человеческого сознания</b> в машинную оболочку давно перестала быть экзотическим хобби.",
    emotions: 'default',
    title: 'Открылся центр загрузки сознания'
  },
  {
    subject: subjects[0],
    img: img1,
    button: "Смотреть далее",
    text: "Избравшие такой путь <b>называют себя «Вознесенными»</b> и борются за признание их отдельной разумной расой, по ряду показателей <b>превосходящей человеческую расу.</b>",
    emotions: 'default',
    title: 'Открылся центр загрузки сознания'
  },
  {
    subject: subjects[1],
    img: img2,
    button: "Смотреть далее",
    text: "К сожалению, многие вознесенные <b>не способны контролировать полученные возможности</b>. В городе участились <b>случаи преступлений киборгов</b>: хулиганство, вандализм, сопротивление полиции.",
    emotions: 'default',
    title: 'Участились случаи преступлений киборгов'
  },
  {
    subject: subjects[1],
    img: img2,
    button: "Смотреть далее",
    text: "Силы полиции не всегда способны <b>противостоять преступникам</b> из вознесенных. Как сообщает оперштаб, было принято решение <b>ввести в городе комендантский час.</b>",
    emotions: 'handup',
    title: 'Участились случаи преступлений киборгов'
  },
  {
    subject: subjects[2],
    img: img3,
    button: "Смотреть далее",
    text: "В качестве ответной меры <b>формируется спецподразделение</b> – «Народное сопротивление». В него входят обычные жители, объединенные в <b>бригады быстрого реагирования</b> и работающие удаленно.",
    emotions: 'default',
    title: 'Формируется народное сопротивление'
  },
  {
    subject: subjects[2],
    img: img3,
    button: "Присоединиться",
    text: "Социально активные жители! Не оставайтесь в стороне! Присоединиться к «Народному сопротивлению» может каждый. Для этого надо всего лишь пройти отборочное интервью.",
    emotions: 'default',
    title: 'Формируется народное сопротивление'
  },
];
