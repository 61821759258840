import axios from "axios";

export interface IFile {
  id: string;
  src: string;
}

const remove = async (id: string) => {
  try {
    const response = await axios.delete(`/files/${id}`);
    return response.status === 204;
  } catch (error) {
    return false;
  }
};

const upload = async (name: string, file: Blob) => {
  try {
    const formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("name", `${name}.webm`);
    const response = await axios.post<IFile>(`/upload`, formData);
    return response.data;
  } catch (error) {}
};

const FilesAPI = { remove, upload };

export default FilesAPI;
