import { useSelector } from "react-redux";

import {
  InterviewFooter,
  InterviewHeader,
  InterviewButton,
  InterviewTitle,
  InterviewWrapper,
} from "./interview.styled";
import { useNavigate, useParams } from "react-router-dom";

import InterviewProgress from "../components/interview/InterviewProgress";
import InterviewQuestion from "../components/interview/InterviewQuestion";
import { QuestionAPI } from "../services/questions.services";
import { selectQuestions } from "../store/questions/questions.selector";
import { useEffect, useMemo } from "react";

const InterviewProccess = () => {
  const navigate = useNavigate();

  const { page } = useParams<"page">();
  const index = page ? parseInt(page) : 0;

  const questions = useSelector(selectQuestions);
  const question = useMemo(
    () => (index >= 0 && index < questions.length ? questions[index] : questions[0]),
    [index, questions]
  );

  useEffect(() => {
    if (questions.length === 0) {
      navigate("/dialogue");
    }
  }, [navigate, questions.length]);

  const handleMoveNext = () => {
    navigate(`/questions/${index + 1}`);
  };

  const handleMoveBack = () => {
    navigate(`/questions/${index - 1}`);
  };

  const handleSubmitAnswers = async () => {
    try {
      const answers = questions.map((item) => ({ question_id: item.id, file_id: item.answer }));
      const response = await QuestionAPI.saveAnswers(answers);
      if (response) {
        navigate(`/dialogue/success`);
      }
    } catch (error: any) {}
  };

  /**
   * Возвращаем статус кнопки Далее
   * @returns boolean
   */
  const isNextDisabled = () => {
    // Если нет вопросов
    if (questions.length === 0) return true;
    // Если нет вопроса
    if (!question) return true;
    // Если нет ответа на текущий вопрос
    if (!question.answer) return true;
    // Если ответ на вопрос меньше 10 секунд
    //if (question.duration !== undefined && question.duration < 10) return true;
  };

  const isLastQuestion = questions.length === index + 1;

  return (
    <InterviewWrapper vertical>
      <InterviewHeader>
        <InterviewTitle />
        <InterviewProgress questions={questions} />
      </InterviewHeader>
      {question && <InterviewQuestion question={question} />}
      <InterviewFooter>
        <InterviewButton disabled={index < 1} onClick={handleMoveBack}>
          Назад
        </InterviewButton>
        {isLastQuestion ? (
          <InterviewButton disabled={isNextDisabled()} onClick={handleSubmitAnswers}>
            Завершить
          </InterviewButton>
        ) : (
          <InterviewButton disabled={isNextDisabled()} onClick={handleMoveNext}>
            Далее
          </InterviewButton>
        )}
      </InterviewFooter>
    </InterviewWrapper>
  );
};

export default InterviewProccess;
