import React from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { Group, Line, Image } from "react-konva";
import useImage from "use-image";

import failedImage from "../assets/failed.png";
import passedImage from "../assets/passed.png";
import { IActivity, StatusType } from "../utils/activities";
import { centroid } from "../utils/centroid";

type PolygonProps = {
  points: number[];
  circle: React.ReactNode;
  activity: IActivity;
  onClick: (activity: IActivity) => void;
};

const source = {
  passed: passedImage,
  failed: failedImage,
};

function Polygon({ points, circle, activity, onClick }: PolygonProps) {
  const handleCursor = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    if (stage) {
      if (e.type === "mouseenter") {
        stage.container().style.cursor = "pointer";
      } else {
        stage.container().style.cursor = "default";
      }
    }
  };

  const StatusHand = ({ status }: { status: StatusType }) => {
    const [image] = useImage(source[status]);
    const center = centroid(points);
    if (!image) return null;

    return (
      <Image
        image={image}
        x={center.x}
        y={center.y}
        offsetX={image.width / 2}
        offsetY={image.height / 2}
        onMouseEnter={handleCursor}
        onMouseLeave={handleCursor}
      />
    );
  };

  const handleClick = () => {
    onClick(activity);
  };

  return (
    <Group>
      <Line
        points={points}
        closed
        fill="rgba(255,166,12, 0.3)"
        fillLinearGradientStartPoint={{ x: points[0], y: points[1] }}
        fillLinearGradientEndPoint={{ x: points[4], y: points[5] }}
        fillLinearGradientColorStops={[0, "#33095675", 1, "#1100448c"]}
        fillPriority={activity.status ? "linear-gradient" : "color"}
        stroke={activity.status ? "#267efe" : "rgba(255,166,12, 1)"}
        strokeWidth={3}
        shadowColor="rgba(0,0,0,1)"
        shadowBlur={10}
        onClick={handleClick}
        onMouseEnter={handleCursor}
        onMouseLeave={handleCursor}
      />
      {React.isValidElement(circle) && React.cloneElement(circle, { status: activity.status })}
      {activity.status && <StatusHand status={activity.status} />}
    </Group>
  );
}

export default Polygon;
