import styled from "styled-components";

import bg from "../assets/dialogue-bg.jpg";
import frame from "../assets/frame.png";
import button from "../assets/bottom-button.png";
import failedImage from "../assets/failed.png";
import passedImage from "../assets/passed.png";

export const DialogueContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 680px;
  background-color: #000022;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const DialogueFrame = styled.div`
  background-image: url(${frame});
  width: 631px;
  height: 237px;
  padding: 35px;
  position: relative;
  margin-top: 50px;
`;

export const DialogueMentor = styled.div`
  align-self: flex-end;
`;

export const DialogueChat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: #4a4552;
  padding: 50px;
  border-radius: 11px;
  box-shadow: 20px 20px #99889f;
  width: 600px;
  height: 500px;
  margin-top: 1rem;
  &::after {
    content: ".";
    font-weight: bold;
    -webkit-text-fill-color: #99889f;
    text-shadow: 22px 22px #99889f;
    text-align: left;
    font-size: 55px;
    width: 55px;
    height: 11px;
    line-height: 30px;
    border-radius: 11px;
    background-color: #4a4552;
    position: absolute;
    display: block;
    bottom: -40px;
    left: 0;
    box-shadow: 22px 22px #99889f;
  }
`;

export const DialogueText = styled.div``;

export const DialogueButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: -30px;
  height: 39px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const DialogueButton = styled.button`
  border: none;
  width: 236px;
  height: 39px;
  background-color: transparent;
  background-image: url(${button});
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #fff;
  padding-bottom: 3px;
`;

type DialogueIconProps = {
    status: string;
};

export const DialogueIcon = styled.div<DialogueIconProps>`
  width: 100px;
  height: 100px;
  background-size: contain;
  top: 75px;
  left: 0;
  background-image: url(${({ status }) => status === 'success' ? passedImage : failedImage}); 
  background-repeat: no-repeat; 
  margin-top: 50px;
  margin-right: 50px;
`;

export const DialogueMissionContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 680px;
  background-color: #000022;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 200px;
`;

export const DialogueMissionFrame = styled.div`
  background-image: url(${frame});
  width: 631px;
  height: 237px;
  padding: 35px;
  position: relative;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
