import React from "react";
import styled from "styled-components";

import leadingDefaultImage from "../assets/leading-default.png";
import leadingHandupImage from "../assets/leading-handup.png";

type LeadingProps = {
  emotion: "default" | "handup";
};

const emotions = {
  default: leadingDefaultImage,
  handup: leadingHandupImage,
};

const LeadingImage = styled.div<LeadingProps>`
  position: absolute;
  width: 910px;
  height: 582px;
  top: 75px;
  left: ${({ emotion }) => emotion === 'default' ? '150px' : '0'};
  background-image: url(${({ emotion }) => emotions[emotion]}); 
  background-repeat: no-repeat; 
`;

function Leading({ emotion }: LeadingProps) {
  return <LeadingImage emotion={emotion} />;
}

export default React.memo(Leading);
