import styled from "styled-components";

import introBackground from "../assets/introbg.jpg";
import buttonImage from "../assets/button.png";

export const IntroduceWrapper = styled.div`
  width: 100%;
  height: 680px;
  background-image: url(${introBackground});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const Credit = styled.div`
  max-width: 300px;
  font-size: 1rem;
  padding: 1rem;
  background: rgb(47, 75, 97);
  background: linear-gradient(175deg, rgba(3, 27, 62, 1) 0%, rgba(47, 75, 97, 1) 100%);
  box-shadow: 5px 5px 0 rgb(91, 167, 224);
  position: absolute;
  top: 20px;
  left: 20px;
  color: #69b1df;
  &::after {
    position: absolute;
    right: -15px;
    bottom: -15px;
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-right: 4px solid rgb(91, 167, 224);
    border-bottom: 4px solid rgb(91, 167, 224);
  }
`;

export const Title = styled.div`
  background-color: RGB(252, 209, 9);
  color: #1a0e00;
  position: absolute;
  bottom: 45px;
  left: 66px;
  width: 880px;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding: 0.5rem;
`;

export const Video = styled.video<{ isVisible?: boolean }>`
  position: absolute;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  width: 100%;
  height: 100%;
  z-index: 999;
`;

export const VideoCover = styled.video<{ isVisible?: boolean }>`
  position: absolute;
  width: 1024px;
  height: 680px;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

export const Button = styled.button`
  position: absolute;
  top: 420px;
  right: 60px;
  border: none;
  width: 275px;
  height: 86px;
  background-color: transparent;
  background-image: url(${buttonImage});
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #fff;
  padding-bottom: 10px;
`;

export const UrgentNews = styled.img`
  position: absolute;
  bottom: 100px;
  left: 66px;
`;
